import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DatePicker, DayOfWeek, defaultDatePickerStrings, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks"
import { getPage } from "../../../../../../app/Pages"
import { setPage } from "../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, addItem, revokeAutorization, getItems, setStatusFilterOption, setIsFilteredSet, setMessage, updateItem } from "./LinkAccountPageSlice"
import { ApiMessage, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../app/Themes"
import { SearchField } from "../../../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, getOperations, printComponent, normalizeKey, printStatement, getAccountAuthorizedPermissions } from "../../../../../../app/Helpers"
import { TableState } from "../../../../../common/Table/TableSate"
import { Message } from "../../../../../common/Message/Message"
import { ShareBox } from "../../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../../common/Popup/Popup"
import { FormType } from "../../../../../forms/FormProps"
import { TreasuryStatus, LimitationType, Operations, Natures, AuthorizedOperation, AuthorizationStatus } from "../../../../../../app/Enums"
import { GetStatementRequest } from "../../../../../../repository/Accountant/account_operation_pb"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import moment from "moment"
import { GetAccountsRequest } from "../../../../../../repository/Accountant/account_pb"
import { GetTreasuriesRequest, GetTreasuriesResponse } from "../../../../../../repository/Accountant/treasury_pb";
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../../PageProps"
import { List } from "../../../../../common/List/List"
import { TreasuriesPage } from "../../../Treasuries/TreasuriesPage"
import { AccountsPage } from "../../AccountsPage"
import { AccountAuthorizationForm } from "../../../../../forms/Accountant/Accounts/AccountAuthorizationForm"
import { GetUsersRequest } from "../../../../../../repository/UserManagement/Customers/user_pb"
import { AuthorizationForm } from "../../../../../forms/Accountant/Accounts/AuthorizationForm"
import { GetAuthorizationsRequest, GetAuthorizationsResponse, RevokeAuthorizationRequest } from "../../../../../../repository/Accountant/authorization_pb"
import { GenerateLinkTokenForm } from "../../../../../forms/Accountant/Accounts/GenerateLinkTokenForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetAuthorizationsRequest;
let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

interface LinkAccountPageProps {
    account: any
}
export const LinkAccountPage: React.FunctionComponent<LinkAccountPageProps & PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        filters: any[],
        statusFilterOption: { key: number, text: string }


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.linkAccountPage.isChangeStateLoading,
            set: state.linkAccountPage.set,
            message: state.linkAccountPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.linkAccountPage.searchText,
            filters: state.linkAccountPage.filters,
            statusFilterOption: state.linkAccountPage.statusFilterOption

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)

    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });




    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setStatusFilterOption({ key: 0, text: "" }))
        req.setStatus(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }

        if (state.statusFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.statusFilterOption.key);
            req.setStatus(wrapper)
            isFiltered = true;

        } else {
            req.setStatus(undefined)
        }


        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetAuthorizationsRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)

            }
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)
        var r = new StringValue();
        r.setValue(props.account?.internalId);
        req.setAccount(r)

        var rx = new Int32Value();
        rx.setValue(AuthorizedOperation.TRIGGERED_TRANSFER);
        req.setOperation(rx)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

        dispatch(setMessage({ body: t("linkAccountDesc", { account: props.account?.name }), type: 4, title: "", data: undefined }))

        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/authorizations/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/authorizations/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.id}</Link >;
            },
        },

        {
            key: 'status',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.status != undefined) {
                    return <Text>{item?.status != undefined ? item?.status == AuthorizationStatus.NEW ? t("active") :
                        item?.status == AuthorizationStatus.USED ? t("used") :
                            item?.status == AuthorizationStatus.EXPIRED ? t("expired") :
                                item?.status == AuthorizationStatus.REVOKED ? t("revoked") : "" : ""}</Text>;
                }

            },
        },
        {
            key: 'addedBy',
            name: t("addedByColumn"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/management/users/" + item?.addedById + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/management/users/" + item?.addedById + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}><Persona
                        text={item?.addedBy == "" ? item?.addedById : item?.addedBy}
                        size={PersonaSize.size40}
                        imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" +  item?.addedById + "/profile.jpg"}

                    /></Link>

            },
        },
        {
            key: 'createdDate',
            name: t("date"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            title: t("edit"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },
        {
            key: 'revoke',
            text: t("revokeLink"),
            title: t("revokeLink"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },


    ];

    const _selectionItemsRevoked: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            title: t("edit"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },


    ];
    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },

        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];







    return (
        <Stack>

            <Popup isOpen={(currentAction == 100)} title={t("generateLinkKey")} onDismiss={() => { setCurrenctAction(0) }} >
                <GenerateLinkTokenForm childrenGap={10} maxWidth={500} type={FormType.ADD} renderObject={props.account} onSuccess={(e) => {
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("editLinkKey")} onDismiss={() => { setCurrenctAction(0) }} >
                <GenerateLinkTokenForm childrenGap={10} maxWidth={280} type={FormType.EDIT} fetchData={true} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>


            {
                state.message != undefined ? (state.message.data != 401) ? <Message
                    body={state.message.body}
                    title={state.message.title}
                    data={state.message.data}
                    onDismiss={() => { dispatch(dismissMessage()) }}
                    type={state.message.type}
                /> :
                    <SessionExpiredDialog />
                    : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : state.set.selected.at(0)?.status == AuthorizationStatus.REVOKED ? _selectionItemsRevoked : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />


            {
                isShareCalloutVisible ? (
                    <Callout
                        ariaLabelledBy={shareCalloutLabelId}
                        target={`#${shareTargetId}`}
                        onDismiss={toggleisShareCalloutVisible}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        beakWidth={20}
                        styles={{
                            root: {
                                width: 370,
                            }
                        }}
                    >
                        <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareLinkToken")} value={`https://${window.location.host}/authorizations/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                    </Callout>
                ) : null
            }


            {
                isActionConfirmationCalloutVisible ? (
                    <Callout
                        ariaLabelledBy={ActionConfirmationLabelId}
                        target={`#${getActionId(currentAction)}`}
                        onDismiss={toggleisActionConfirmationCalloutVisible}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        beakWidth={20}
                        styles={{
                            root: {
                                width: 370,
                            }
                        }}
                    >
                        <Stack tokens={{ padding: '20px 24px ' }}>
                            <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                                {(currentAction == 1 ? t("revokeLink") : "")}
                            </Text>
                            <Text block variant="small">
                                {(currentAction == 1 ? t("revokeLinkAccountsDesc") : "")}
                            </Text>
                            {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                            <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                    <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                    <PrimaryButton onClick={() => {
                                        toggleisActionConfirmationCalloutVisible()
                                        if (state.set.selected.length > 0) {
                                            if (currentAction == 1) {

                                                let req = new RevokeAuthorizationRequest();
                                                req.setId(state.set.selected.at(0)?.id)
                                                actionPromise?.abort();
                                                actionPromise = dispatch(revokeAutorization({ body: req, headers: getHeaders() }))
                                                actionPromise.unwrap().then((e: any) => {
                                                    _selection.setAllSelected(false)
                                                })
                                            }
                                        }
                                    }} text={(currentAction == 1 ? t("revoke") : "")}  >

                                    </PrimaryButton>
                                </Stack>
                            </FocusZone>
                        </Stack>
                    </Callout>
                ) : null
            }



            {
                isCalloutVisible ? (
                    <Callout
                        ariaLabelledBy={labelId}

                        target={`#${calloutTargetId}`}
                        onDismiss={toggleIsCalloutVisible}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        isBeakVisible={true}
                    >
                        <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                            <Text block variant="mediumPlus" >
                                {t("filterDesc")}
                            </Text>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                                <Label>{t("status")}</Label>
                                <Dropdown
                                    selectedKey={state.statusFilterOption.key}
                                    options={[{ key: 0, text: t("all") }, { key: AuthorizationStatus.NEW, text: t("active") },
                                    { key: AuthorizationStatus.USED, text: t("used") }, { key: AuthorizationStatus.EXPIRED, text: t("expired") }, { key: AuthorizationStatus.REVOKED, text: t("revoked") }]}
                                    styles={{ root: { width: 300 } }}
                                    onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                        dispatch(setStatusFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text ?? "" }))

                                    })}

                                />
                            </Stack>
                            <DialogFooter>
                                <PrimaryButton text={t("search")} onClick={(e => {
                                    search(state.searchText);
                                    toggleIsCalloutVisible();
                                })} />
                                <DefaultButton text={t("clearFilter")} onClick={() => {
                                    clearFilter();
                                }} />

                            </DialogFooter>
                        </Stack>
                    </Callout>
                ) : null
            }

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {


                                if (e.key == 1) {
                                    dispatch(setStatusFilterOption({ key: 0, text: "" }))
                                    req.setStatus(undefined)
                                }

                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="authorizations"
                items={state.set.items}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                columns={c}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 225px)" : "calc(100vh - 273px)"} selection={_selection}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {
                props.isSelection ? <DialogFooter>
                    <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                        if (props.onItemSelected && state.set.selected.length > 0) {
                            props.onItemSelected(state.set.selected.at(0))
                        }
                    }} >

                        <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                    </PrimaryButton>
                    <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }} />
                </DialogFooter> : undefined
            }
        </Stack >



    );
}




