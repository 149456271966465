
import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, IPersonaProps, NormalPeoplePicker, IPickerItemProps, ValidationState, PeoplePickerItem } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../../app/Hooks"
import { getPage } from "../../../../../../../app/Pages"
import { setPage } from "../../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../../common/Table/Table"
import { ApiMessage, CLIENT_PROFILE_LOGO_URL, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../../../common/Message/Message"
import { ShareBox } from "../../../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { dismissMessage, get, reset, resetItem, updateItem, removeAutorization, getAccount } from "./AccountAuthorizationDetailsPageSlice"
import { Popup } from "../../../../../../common/Popup/Popup"
import { FormType } from "../../../../../../forms/FormProps"
import { DynamicField } from "../../../../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../../../../common/DataView/TextView"
import { ProfileView } from "../../../../../../common/DataView/ProfileView"
import { TreasuryStatus, LimitationType, Natures, Operations, AuthorizedOperation } from "../../../../../../../app/Enums"
import { TreasuriesForm } from "../../../../../../forms/Accountant/Treasuries/TreasuriesForm"
import { TableState } from "../../../../../../common/Table/TableSate"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { GetLimitationsRequest } from "../../../../../../../repository/Accountant/limitation_pb"
import { LinkView } from "../../../../../../common/DataView/LinkView"
import { currencyFormatter, getOperations, printComponent, normalizeKey, printStatement, getAccountAuthorizedPermissions } from "../../../../../../../app/Helpers"
import { GetTransactionRequest, ExportTransactionRequest } from "../../../../../../../repository/Accountant/account_operation_pb"
import { TreasuryTransactionsForm } from "../../../../../../forms/Accountant/TreasuryTransactions/TreasuryTransactionsForm"
import { AccountTransactionsForm } from "../../../../../../forms/Accountant/AccountTransactions/AccountTransactionsForm"
import { useReactToPrint } from "react-to-print"
import statement from "../../../../../../../repository/Accountant/account_operation_pb"
import { GetAccountAuthorizationRequest, DeleteAccountAuthorizationRequest, GetAccountAuthorizationResponse } from "../../../../../../../repository/Accountant/account_autorization_pb"
import { AccountAuthorizationForm } from "../../../../../../forms/Accountant/Accounts/AccountAuthorizationForm"
import { AuthorizationForm } from "../../../../../../forms/Accountant/Accounts/AuthorizationForm"
import { GetAccountRequest } from "../../../../../../../repository/Accountant/account_pb"


const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetAccountAuthorizationRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "accountsManagement/accountAuthorization/details"
export const AccountAuthorizationDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any,
        account: any,
        accountLoading: boolean

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.accountAuthorizationDetailsPage.isChangeStateLoading,
            message: state.accountAuthorizationDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.accountAuthorizationDetailsPage.isLoading,
            item: state.accountAuthorizationDetailsPage.item,
            account: state.accountAuthorizationDetailsPage.account,
            accountLoading: state.accountAuthorizationDetailsPage.accountLoading
        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });
    const getActionId = (action: number): string => {
        if (action == 1)
            return deleteActionConfirmationcalloutTargetId;
        else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetAccountAuthorizationRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            getPromise.unwrap().then((e: GetAccountAuthorizationResponse.AsObject) => {
                if (e && e?.success?.account?.id?.value) {
                    var ra = new GetAccountRequest();
                    ra.setId(e?.success?.account?.id?.value)
                    dispatch(getAccount({ body: ra, headers: getHeaders() }))
                   

                }
            })
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },

        {
            key: 'edit',
            text: t("edit"),
            title: t('edit'),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                setCurrenctAction(101)

            }

        },

        {
            key: 'remove',
            text: t("removeAuthorizedClient"),
            title: t("removeAuthorizedClient"),
            disabled: state.accountLoading,
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },


    ];




    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }


            <Popup isOpen={(currentAction == 101)} title={t("editAccountAuthorization")} onDismiss={() => { setCurrenctAction(0) }} >
                <AccountAuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT}  renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareAccountAuthorization")} value={`https://${window.location.host}/account-authorizations/${state.item?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}

            {
                isActionConfirmationCalloutVisible ? (
                    <Callout
                        ariaLabelledBy={ActionConfirmationLabelId}
                        target={`#${getActionId(currentAction)}`}
                        onDismiss={toggleisActionConfirmationCalloutVisible}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        beakWidth={20}
                        styles={{
                            root: {
                                width: 370,
                            }
                        }}
                    >
                        <Stack tokens={{ padding: '20px 24px ' }}>
                            <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                                {(currentAction == 1 ? t("removeAuthorizedClient") : "")}
                            </Text>
                            <Text block variant="small">
                                {(currentAction == 1 ? t("removeAuthorizedClientDesc") : "")}
                            </Text>
                            {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                            <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                    <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                    <PrimaryButton onClick={() => {
                                        toggleisActionConfirmationCalloutVisible()
                                        if (state.item) {
                                            if (currentAction == 1) {
                                                if (state.account?.isAuthorizationRequired) {
                                                    setCurrenctAction(14)
                                                    return;
                                                }
                                                let req = new DeleteAccountAuthorizationRequest();
                                                req.setId(state.item?.id)
                                                actionPromise?.abort();
                                                actionPromise = dispatch(removeAutorization({ body: req, headers: getHeaders() }))
                                                actionPromise.unwrap().then((e: any) => {
                                                    navigate(-1)
                                                })

                                            }
                                        }

                                    }} text={(currentAction == 1 ? t("remove") : "")}  >

                                    </PrimaryButton>
                                </Stack>
                            </FocusZone>
                        </Stack>
                    </Callout>
                ) : null
            }
            <Popup isOpen={(currentAction == 14)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                    account: state.account,
                    operation: AuthorizedOperation.DELETE_ACCOUNT_AUTHORIZER,
                    parameters: [{ key: "AccountAuthorization", value: state.item?.id, viewValue: state.item?.authorizedClientName },
                    ],
                }} onSuccess={(e) => {
                    if (state.item && e.authCode) {
                        if (currentAction == 14) {

                            let req = new DeleteAccountAuthorizationRequest();
                            req.setId(state.item?.id)
                            var r = new StringValue();
                            r.setValue(e.authCode)
                            req.setAuthcode(r)
                            actionPromise?.abort();
                            setCurrenctAction(1)
                            actionPromise = dispatch(removeAutorization({ body: req, headers: getHeaders() }))
                            actionPromise.unwrap().then((e: any) => {
                                navigate(-1)
                            })
                        }
                    } else {
                        setCurrenctAction(0)
                    }

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>


            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" +  state.item?.addedById + "/profile.jpg"}

                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <LinkView label={t("account")} value={state.item?.accountName == "" ? state.item?.accountId : state.item?.accountName}
                                    url={"/accounts/" + state.item?.accountId + ((org == -1) ? "" : "?org=" + org)} />


                                <ProfileView label={t("authorizedClient")} value={state.item?.authorizedClientName == "" ? state.item?.authorizedClientId : state.item?.authorizedClientName}
                                    url={"/customers/users/" + state.item?.authorizedClientId + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.authorizedClientName == "" ? state.item?.authorizedClientId : state.item?.authorizedClientName}
                                    imageUrl={CLIENT_PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.authorizedClientId + "/profile.jpg"}

                                    size={PersonaSize.size40} />

                            </Stack>

                            <TextView label={t("permissions")} value={state.item?.permissions?.map((a: Number) => {
                                return t(normalizeKey(getAccountAuthorizedPermissions().find(x => x.key == a)?.text ?? "" + a)) + ", ";
                            })} />


                            <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                            {state.item?.extraFields.map((e: DynamicField, index: number) => {
                                return <Stack key={e.key}>
                                    <TextView label={e.label ?? ""} value={e?.value} />

                                </Stack>
                            })}
                        </Stack>


                    </div>


            }


        </Stack>



    );
}




