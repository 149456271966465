import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, IPersonaProps, NormalPeoplePicker, IPickerItemProps, ValidationState, PeoplePickerItem, ShimmerElementType, Shimmer, MaskedTextField, FocusZoneDirection } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { ApiMessage, CLIENT_PROFILE_LOGO_URL, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { dismissMessage, get, reset, resetItem, updateItem, changeOwnership, closeAccount, updateLimit, updateStatus, resetLimitations, setSelectedUserItems, getClinetUsers, resetUsers, setIsFilteredOwnersSet, setSelectedLimitationItems, getLimitations, setIsFilteredLimitationsSet, getBalance, getAccountTypes, setSelectedAccountTypeItems, resetAccountTypes, setIsFilteredAccountTypesSet, updateType, updateAuthorization, removeLink } from "./AccountDetailsPageSlice"
import { Popup } from "../../../../common/Popup/Popup"
import { FormType } from "../../../../forms/FormProps"
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../../common/DataView/TextView"
import { ProfileView } from "../../../../common/DataView/ProfileView"
import { CloseAccountRequest, GetAccountRequest, RevokeLinkRequest, TransferAccountOwnershipReplay, TransferAccountOwnershipRequest, UpdateAccountLimitationRequest, UpdateAccountStatusRequest, UpdateAccountTypeRequest, UpdateAuthorizationRequirementReplay, UpdateAuthorizationRequirementRequest } from "../../../../../repository/Accountant/account_pb"
import { AccountStatus, AuthorizedOperation, LimitationType, TransferControl } from "../../../../../app/Enums"
import { AccountsForm } from "../../../../forms/Accountant/Accounts/AccountsForm"
import { TableState } from "../../../../common/Table/TableSate"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { GetUsersRequest } from "../../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../../repository/Accountant/limitation_pb"
import { LinkView } from "../../../../common/DataView/LinkView"
import { GetBalanceRequest } from "../../../../../repository/Accountant/account_operation_pb"
import { currencyFormatter, printComponent, normalizeKey } from "../../../../../app/Helpers"
import { TransferForm } from "../../../../forms/Accountant/Operations/Transfer/TransferForm"
import { DepositForm } from "../../../../forms/Accountant/Operations/Deposit/DepositForm"
import { WithdrawForm } from "../../../../forms/Accountant/Operations/Withdraw/WithdrawForm"
import { AccountStatementPage } from "./AccountStatementPage/AccountStatementPage"
import { AccountAuthorizationPage } from "./AccountAuthorizationPage/AccountAuthorizationPage"


import { GetAccountTypesRequest } from "../../../../../repository/Accountant/account_type_pb"
import { useReactToPrint } from "react-to-print"
import { List } from "../../../../common/List/List"
import { LimitationsPage } from "../../Limitations/LimitationsPage"
import { AccountTypesPage } from "../../AccountTypes/AccountTypesPage"
import { UsersPage } from "../../../Customers/Users/UsersPage"
import { AuthorizationForm } from "../../../../forms/Accountant/Accounts/AuthorizationForm"
import { ExternalTransferForm } from "../../../../forms/Accountant/Operations/ExternalTransfare/ExternalTransfareForm"
import { LinkAccountForm } from "../../../../forms/Accountant/Accounts/LinkAccountForm"
import { LinkAccountPage } from "./LinkAccountPage/LinkAccountPage"
import { TransferControlForm } from "../../../../forms/Accountant/Accounts/TransferControlForm"
import { SignaturesPage } from "./SignaturesPage/SignaturesPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetAccountRequest;
let balanceReq: GetBalanceRequest;
let limitationReq: GetLimitationsRequest;
let clintsReq: GetUsersRequest;
let accountTypesReq: GetAccountTypesRequest;

let org: number = -1;

let getPromise: any;
let getBalancePromise: any;
let actionPromise: any;
let getFilterPromise: any;


const key = "accountsManagement/accounts/details"
export const AccountDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any,
        owners: TableState,
        limitations: TableState
        accountTypes: TableState
        balances: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.accountDetailsPage.isChangeStateLoading,
            message: state.accountDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.accountDetailsPage.isLoading,
            item: state.accountDetailsPage.item,
            limitations: state.accountDetailsPage.limitations,
            owners: state.accountDetailsPage.owners,
            accountTypes: state.accountDetailsPage.accountTypes,
            balances: state.accountDetailsPage.balances

        }
    })

    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeAccountActionConfirmationcalloutTargetId = useId('close-account-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const updateLimitationActionConfirmationcalloutTargetId = useId('update-limitation-action-callout-target');
    const transferOwnershipActionConfirmationcalloutTargetId = useId('transfer-ownership-action-callout-target');
    const updateTypeActionConfirmationcalloutTargetId = useId('update-type-action-callout-target');
    const updateConfirmationcalloutTargetId = useId('update-action-callout-target');
    const updateAuthcalloutTargetId = useId('update-auth-action-callout-target');
    const removeLinkActionConfirmationcalloutTargetId = useId('remove-link-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const updateLimtCalloutLabelId = useId('limit-callout-label');
    const transfareOwnerShipCalloutLabelId = useId('transfareOwnerShip-callout-label');
    const updateTypeCalloutLabelId = useId('type-callout-label');
    const updateAuthCalloutLabelId = useId('auth-callout-label');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);
    const [isupdateLimitationConfirmationCalloutVisible, { toggle: toggleisUpdateLimitationConfirmationCalloutVisible }] = useBoolean(false);
    const [isTransferOwnershipConfirmationCalloutVisible, { toggle: toggleisTransferOwnershipConfirmationCalloutVisible }] = useBoolean(false);
    const [isUpdateTypeConfirmationCalloutVisible, { toggle: toggleisUpdateTypeConfirmationCalloutVisible }] = useBoolean(false);
    const [isAuthConfirmationCalloutVisible, { toggle: toggleisUpdateAuthConfirmationCalloutVisible }] = useBoolean(false);

    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });

    const [currentAction, setCurrenctAction] = useState(0)

    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeAccountActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else if (action == 4) {
            return updateLimitationActionConfirmationcalloutTargetId;
        } else if (action == 3) {
            return transferOwnershipActionConfirmationcalloutTargetId;
        } else if (action == 11) {
            return updateTypeActionConfirmationcalloutTargetId;
        } else if (action == 12) {
            return updateAuthcalloutTargetId;
        } else if (action == 15) {
            return removeLinkActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetAccountRequest();
        clintsReq = new GetUsersRequest();
        limitationReq = new GetLimitationsRequest();
        balanceReq = new GetBalanceRequest();
        accountTypesReq = new GetAccountTypesRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                clintsReq.setOrganization(wrapper)
                limitationReq.setOrganization(wrapper)
                accountTypesReq.setOrganization(wrapper)

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        const boolv = new BoolValue();
        boolv.setValue(true);

        accountTypesReq.setNumofresults(state.accountTypes.numberOfResults)
        accountTypesReq.setOrder(state.accountTypes.isDescending)
        accountTypesReq.setIsavailable(boolv)

        clintsReq.setNumofresults(state.owners.numberOfResults)
        clintsReq.setOrder(state.owners.isDescending)
        limitationReq.setNumofresults(state.limitations.numberOfResults)
        limitationReq.setOrder(state.limitations.isDescending)
        const type = new Int32Value();
        type.setValue(LimitationType.ACCOUNT_LIMITATION);
        limitationReq.setType(type)
        limitationReq.setIsavailable(boolv)

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            getPromise.unwrap().then((e: any) => {
                if (hash == "#statement") {
                    setSelectedKey("1");
                } else if (hash == "#authorizations") {
                    setSelectedKey("2");
                } else if (hash == "#linkaccount") {
                    setSelectedKey("3");
                } else if (hash == "#signatures") {
                    setSelectedKey("4");
                } else {
                    setSelectedKey("0");
                }
            });
        } else {
            navigate(-1)
        }

        if (id) {
            balanceReq.setAccountid(id)
            getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
        } else {
            navigate(-1)
        }

        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            getFilterPromise?.abort();
            getBalancePromise?.abort();
            dispatch(reset());
        }
    }, [])

    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'transferMoney',
            text: t("transferMoney"),
            iconProps: { iconName: 'Switch' },
            disabled: state.item?.status != AccountStatus.ACTIVE,
            title: t("transferMoney"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(5)
                }
            }
        },
        {
            key: 'externalTransferMoney',
            text: t("externalTransferMoney"),
            iconProps: { iconName: 'SwitcherStartEnd' },
            disabled: state.item?.status != AccountStatus.ACTIVE,
            title: t("externalTransferMoney"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(8)
                }
            }
        },
        {
            key: 'deposit',
            text: t("deposit"),
            iconProps: { iconName: 'Installation' },
            disabled: state.item?.status != AccountStatus.ACTIVE,
            title: t("deposit"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(6)
                }
            }
        },

        {
            key: 'withdraw',
            text: t("withdraw"),
            iconProps: { iconName: 'PublishContent' },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("withdraw"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(7)
                }
            }
        },



    ];
    const _selectionItemsLinkEdit: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
                getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))

            }
        },
        {
            key: 'edit',
            text: t("edit"),
            iconProps: { id: updateConfirmationcalloutTargetId, iconName: 'EditSolid12' },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("edit"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && (currentAction == 4 || currentAction == 3 || currentAction == 11) ? undefined : e.iconProps} text={state.isChangeStateLoading && (currentAction == 4 || currentAction == 3 || currentAction == 11) ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && (currentAction == 4 || currentAction == 3 || currentAction == 11) ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            subMenuProps: {
                items: [
                    {
                        key: 'editInfo',
                        text: t('editInfo'),
                        title: t("editInfo"),
                        iconProps: { iconName: 'EditContact', styles: { root: { fontWeight: "bold" } } },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(101)
                            }

                        }
                    },
                    {
                        key: 'updateLimitation',
                        text: t("updateLimitation"),
                        iconProps: { iconName: "MaximumValue" },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("updateLimitation"),

                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(4)
                                toggleisUpdateLimitationConfirmationCalloutVisible()

                            }
                        },
                    },
                    {
                        key: 'transferOwnership',
                        text: t("transferOwnership"),
                        iconProps: { iconName: "Transition", styles: { root: { fontWeight: "bold" } } },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("transferOwnership"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(3)
                                toggleisTransferOwnershipConfirmationCalloutVisible()
                            }
                        },
                    },

                    {
                        key: 'updateType',
                        text: t("updateType"),
                        iconProps: { iconName: "ChangeEntitlements" },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("updateType"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(11)
                                toggleisUpdateTypeConfirmationCalloutVisible()

                            }
                        },
                    },
                ]
            },
        },
        {
            key: 'auth',
            text: (state.item?.isAuthorizationRequired) ? t("authorizationDisable") : t("authorizationEnable"),
            iconProps: { id: updateAuthcalloutTargetId, iconName: (state.item?.isAuthorizationRequired) ? "Unlock" : "LockSolid", styles: { root: { fontWeight: (state.item?.status == AccountStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: (state.item?.isAuthorizationRequired) ? t("authorizationDisable") : t("authorizationEnable"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 12 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 12 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 12 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(12)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'status',
            text: (state.item?.status == AccountStatus.ACTIVE) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.item?.status == AccountStatus.ACTIVE) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.item?.status == AccountStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: (state.item?.status == AccountStatus.ACTIVE) ? t("deactivate") : t("activate"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'close',
            text: t("closeAccount"),
            iconProps: { id: closeAccountActionConfirmationcalloutTargetId, iconName: "Blocked", styles: { root: { fontWeight: "bold" } } },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("closeAccount"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'link',
            text: t("accountLinking"),
            iconProps: { id: removeLinkActionConfirmationcalloutTargetId, iconName: 'Link' },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("accountLinking"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 15 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 15 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 15 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (state.item?.isLinkingMetadataSet == false) {
                    if (!state.isChangeStateLoading) {
                        setCurrenctAction(state.item?.isLinkingMetadataSet ? 104 : 103)
                    }
                }
            },
            subMenuProps: {
                items: [
                    {
                        key: 'updateLink',
                        text: t("updateLinkStatus"),
                        iconProps: { iconName: 'EditSolid12' },
                        disabled: state.item?.status != AccountStatus.ACTIVE,
                        title: t("updateLinkStatus"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(state.item?.isLinkingMetadataSet ? 104 : 103)
                            }
                        }
                    },
                    {
                        key: 'removeLink',
                        text: t("removeLink"),
                        iconProps: { iconName: 'remove' },
                        disabled: state.item?.status != AccountStatus.ACTIVE,
                        title: t("removeLink"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(15)
                                toggleisActionConfirmationCalloutVisible()

                            }
                        }
                    },

                ]
            },


        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];


    const _selectionItemsLinkAdd: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
                getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))

            }
        },
        {
            key: 'edit',
            text: t("edit"),
            iconProps: { id: updateConfirmationcalloutTargetId, iconName: 'EditSolid12' },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("edit"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && (currentAction == 4 || currentAction == 3 || currentAction == 11) ? undefined : e.iconProps} text={state.isChangeStateLoading && (currentAction == 4 || currentAction == 3 || currentAction == 11) ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && (currentAction == 4 || currentAction == 3 || currentAction == 11) ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            subMenuProps: {
                items: [
                    {
                        key: 'editInfo',
                        text: t('editInfo'),
                        title: t("editInfo"),
                        iconProps: { iconName: 'EditContact', styles: { root: { fontWeight: "bold" } } },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(101)
                            }

                        }
                    },
                    {
                        key: 'updateLimitation',
                        text: t("updateLimitation"),
                        iconProps: { iconName: "MaximumValue" },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("updateLimitation"),

                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(4)
                                toggleisUpdateLimitationConfirmationCalloutVisible()

                            }
                        },
                    },
                    {
                        key: 'transferOwnership',
                        text: t("transferOwnership"),
                        iconProps: { iconName: "Transition", styles: { root: { fontWeight: "bold" } } },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("transferOwnership"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(3)
                                toggleisTransferOwnershipConfirmationCalloutVisible()
                            }
                        },
                    },

                    {
                        key: 'updateType',
                        text: t("updateType"),
                        iconProps: { iconName: "ChangeEntitlements" },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("updateType"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(11)
                                toggleisUpdateTypeConfirmationCalloutVisible()

                            }
                        },
                    },
                    {
                        key: 'transferControl',
                        text: t("transferControl"),
                        iconProps: { iconName: "BranchCompare" },
                        disabled: state.item?.status == AccountStatus.CLOSED,
                        title: t("transferControl"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(16)

                            }
                        },
                    },
                ]
            },
        },
        {
            key: 'auth',
            text: (state.item?.isAuthorizationRequired) ? t("authorizationDisable") : t("authorizationEnable"),
            iconProps: { id: updateAuthcalloutTargetId, iconName: (state.item?.isAuthorizationRequired) ? "Unlock" : "LockSolid", styles: { root: { fontWeight: (state.item?.status == AccountStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: (state.item?.isAuthorizationRequired) ? t("authorizationDisable") : t("authorizationEnable"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 12 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 12 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 12 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(12)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'status',
            text: (state.item?.status == AccountStatus.ACTIVE) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.item?.status == AccountStatus.ACTIVE) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.item?.status == AccountStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: (state.item?.status == AccountStatus.ACTIVE) ? t("deactivate") : t("activate"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'close',
            text: t("closeAccount"),
            iconProps: { id: closeAccountActionConfirmationcalloutTargetId, iconName: "Blocked", styles: { root: { fontWeight: "bold" } } },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("closeAccount"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },


        {
            key: 'link',
            text: t("accountLinking"),
            iconProps: { iconName: 'Link' },
            disabled: state.item?.status == AccountStatus.CLOSED,
            title: t("accountLinking"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(state.item?.isLinkingMetadataSet ? 104 : 103)
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const ownersSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('clients'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.owners.hasMore && !state.owners.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.owners.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserItems([state.owners.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.owners.items.length > 0 && !state.owners.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.owners.items.at(state.owners.items.length - 1).internalId);
                clintsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.owners.items.length > 0 ? state.owners.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.owners.items.length == 0 ? state.owners.isFetching : false,
        isSearching: state.owners.items.length > 0 ? state.owners.isFetching : false,


    };


    const onOwnersFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredOwnersSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }


    const accountTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('accountTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accountTypes.hasMore && !state.accountTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                accountTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false,
        isSearching: state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false,


    };
    const onAccountTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        accountTypesReq.setSearch(wrapper)
        accountTypesReq.setNextto(undefined)
        dispatch(setIsFilteredAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
        return [];

    }


    const limitationsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('limitations'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.limitations.hasMore && !state.limitations.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.limitations.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                limitationReq.setNextto(wrapper)
                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
            }
        },
        suggestions: state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.limitations.items.length == 0 ? state.limitations.isFetching : false,
        isSearching: state.limitations.items.length > 0 ? state.limitations.isFetching : false,


    };
    const onLimitationsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetLimitations())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        limitationReq.setSearch(wrapper)
        limitationReq.setNextto(undefined)
        dispatch(setIsFilteredLimitationsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Popup isOpen={(currentAction == 101)} title={t("editAccount")} onDismiss={() => { setCurrenctAction(0) }} >
                <AccountsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 16)} title={t("transferControl")} onDismiss={() => { setCurrenctAction(0) }} >
                <TransferControlForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 5)} title={t("transfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TransferForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{ from: state.item }} onSuccess={(e) => {
                    //setCurrenctAction(0)
                    getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 8)} title={t("externalTransfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <ExternalTransferForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{ from: state.item }} onSuccess={(e) => {
                    getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                    //setCurrenctAction(0)
                    // getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 103 || currentAction == 104)} title={t("accountLinking")} onDismiss={() => { setCurrenctAction(0) }} >
                <LinkAccountForm childrenGap={10} maxWidth={280} type={currentAction == 104 ? FormType.EDIT : FormType.ADD} fetchData={false} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 6)} title={t("deposit")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <DepositForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{ account: state.item }} onSuccess={(e) => {
                    //  setCurrenctAction(0)
                    getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 7)} title={t("withdraw")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <WithdrawForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{ account: state.item }} onSuccess={(e) => {
                    // setCurrenctAction(0)
                    getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 1000)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(3)
                    toggleisTransferOwnershipConfirmationCalloutVisible()
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Popup isOpen={(currentAction == 1001)} title={t("limitations")} onDismiss={() => { setCurrenctAction(0) }} >

                <LimitationsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedLimitationItems([e]))
                    setCurrenctAction(4)
                    toggleisUpdateLimitationConfirmationCalloutVisible()

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1002)} title={t("accountTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountTypeItems([e]))
                    setCurrenctAction(11)
                    toggleisUpdateTypeConfirmationCalloutVisible()
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                    account: state.item,
                    operation: AuthorizedOperation.UPDATE_AUTHORIZATION_REQUIREMENT,
                    parameters: [{ key: "IsAuthorizationRequired", value: (!state.item?.isAuthorizationRequired) + "", viewValue: "" },
                    ],
                }} onSuccess={(e) => {
                    if (state.item && e.authCode) {
                        if (currentAction == 13) {
                            let req = new UpdateAuthorizationRequirementRequest();
                            req.setId(state.item?.internalId)
                            var r = new StringValue();
                            r.setValue(e.authCode)
                            req.setAuthcode(r)
                            req.setIsauthorizationrequired(!state.item.isAuthorizationRequired)
                            actionPromise?.abort();
                            setCurrenctAction(12)
                            actionPromise = dispatch(updateAuthorization({ body: req, headers: getHeaders() }))
                            actionPromise.unwrap().then((e: any) => {
                                setCurrenctAction(0)
                            })
                        }
                    } else {
                        setCurrenctAction(0)
                    }
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 14)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                    account: state.item,
                    operation: AuthorizedOperation.TRANSFER_OWNERSHIP,
                    parameters: [{ key: "Username", value: state.owners?.selected?.at(0)?.username, viewValue: "" },
                    ],
                }} onSuccess={(e) => {
                    if (state.item && e.authCode) {
                        if (currentAction == 14) {
                            let req = new TransferAccountOwnershipRequest();
                            req.setId(state.item?.internalId)
                            req.setOwner(state.owners?.selected?.at(0)?.internalId)
                            var r = new StringValue();
                            r.setValue(e.authCode)
                            req.setAuthcode(r)
                            actionPromise?.abort();
                            setCurrenctAction(3)
                            actionPromise = dispatch(changeOwnership({ body: req, headers: getHeaders() }))
                            actionPromise.unwrap().then((e: any) => {
                                setCurrenctAction(0)
                            })
                        }
                    } else {
                        setCurrenctAction(0)
                    }
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            {isUpdateTypeConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={updateTypeCalloutLabelId}
                    target={`#${updateConfirmationcalloutTargetId}`}
                    onDismiss={() => { dispatch(setSelectedAccountTypeItems([])); toggleisUpdateTypeConfirmationCalloutVisible() }}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text >{t("typeUpdatehint")}</Text>
                        <Label required>{t("accountType")}</Label>
                        <List
                            inputProps={{ placeholder: t("accountType"), required: true }}
                            disabled={state.isLoading}
                            suggestionsHeaderText={t('accountTypes')}
                            isLoading={state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false}
                            isSearching={state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false}
                            moreSuggestionsAvailable={state.accountTypes.hasMore && !state.accountTypes.isFetching}
                            suggestions={state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                            onGetMoreResults={() => {
                                if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                                    accountTypesReq.setNextto(wrapper)
                                    getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                }
                            }}
                            onSuggestionClick={(ev?, item?: any, index?: number) => {
                                var u = state.accountTypes.items.findIndex(e => e.id == item.key)
                                if (u >= 0) {
                                    dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
                                }
                            }}
                            isPeoplePicker={false}
                            selectedItems={state.accountTypes.selected.length > 0 ? state.accountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                            onChange={(e) => {
                                dispatch(setSelectedAccountTypeItems([]))

                            }}
                            onEmptyInputFocus={() => {
                                dispatch(resetAccountTypes())
                                accountTypesReq.setSearch(undefined)
                                accountTypesReq.setNextto(undefined)
                                dispatch(setIsFilteredAccountTypesSet(false))
                                getFilterPromise?.abort()
                                getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                return []
                            }}
                            onFilterChanged={onAccountTypesFilterChanged}
                            endButtons={[
                                {
                                    title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                    onClick: () => {
                                        toggleisUpdateTypeConfirmationCalloutVisible();
                                        setCurrenctAction(1002)

                                    }
                                }]}
                        />

                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation >
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={() => { dispatch(setSelectedAccountTypeItems([])); toggleisUpdateTypeConfirmationCalloutVisible() }}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisUpdateTypeConfirmationCalloutVisible()
                                    if (state.item && state.accountTypes?.selected?.at(0)?.id) {
                                        if (currentAction == 11) {
                                            let req = new UpdateAccountTypeRequest();
                                            req.setId(state.item?.internalId)
                                            req.setAccounttype(state.accountTypes?.selected?.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateType({ body: req, headers: getHeaders() }))
                                        }
                                    }

                                }} text={t("update")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>


                </Callout>
            ) : null}


            {isupdateLimitationConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={updateLimtCalloutLabelId}
                    target={`#${updateConfirmationcalloutTargetId}`}
                    onDismiss={() => { dispatch(setSelectedLimitationItems([])); toggleisUpdateLimitationConfirmationCalloutVisible() }}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text>{t("limitationUpdatehint")}</Text>
                        <Label required>{t("limitation")}</Label>
                        <List
                            inputProps={{ placeholder: t("limitation"), required: true }}
                            disabled={state.isLoading}
                            suggestionsHeaderText={t('limitations')}
                            isLoading={state.limitations.items.length == 0 ? state.limitations.isFetching : false}
                            isSearching={state.limitations.items.length > 0 ? state.limitations.isFetching : false}
                            moreSuggestionsAvailable={state.limitations.hasMore && !state.limitations.isFetching}
                            suggestions={state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                            onGetMoreResults={() => {
                                if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                                    limitationReq.setNextto(wrapper)
                                    getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                }
                            }}
                            onSuggestionClick={(ev?, item?: any, index?: number) => {
                                var u = state.limitations.items.findIndex(e => e.id == item.key)
                                if (u >= 0) {
                                    dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
                                }
                            }}
                            isPeoplePicker={false}
                            selectedItems={state.limitations.selected.length > 0 ? state.limitations.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                            onChange={(e) => {
                                dispatch(setSelectedLimitationItems([]))

                            }}
                            onEmptyInputFocus={() => {
                                dispatch(resetLimitations())
                                limitationReq.setSearch(undefined)
                                limitationReq.setNextto(undefined)
                                dispatch(setIsFilteredLimitationsSet(false))
                                getFilterPromise?.abort()
                                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                return []
                            }}
                            onFilterChanged={onLimitationsFilterChanged}
                            endButtons={[
                                {
                                    title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                    onClick: () => {
                                        toggleisUpdateLimitationConfirmationCalloutVisible()

                                        setCurrenctAction(1001)

                                    }
                                }]}
                        />


                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={() => { dispatch(setSelectedLimitationItems([])); toggleisUpdateLimitationConfirmationCalloutVisible() }}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisUpdateLimitationConfirmationCalloutVisible()
                                    if (state.item && state.limitations?.selected?.at(0)?.id) {
                                        if (currentAction == 4) {
                                            let req = new UpdateAccountLimitationRequest();
                                            req.setId(state.item?.internalId)
                                            req.setLimitation(state.limitations?.selected?.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateLimit({ body: req, headers: getHeaders() }))
                                        }
                                    }

                                }} text={t("update")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>


                </Callout>
            ) : null}

            {isTransferOwnershipConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={transfareOwnerShipCalloutLabelId}
                    target={`#${updateConfirmationcalloutTargetId}`}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    onDismiss={() => { dispatch(setSelectedUserItems([])); toggleisTransferOwnershipConfirmationCalloutVisible() }}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text>{t("transfareOwnershipHint")}</Text>
                        <Label required>{t("client")}</Label>

                        <List
                            isPeoplePicker={true}
                            suggestionsHeaderText={t('clients')}
                            isLoading={state.owners.items.length == 0 ? state.owners.isFetching : false}
                            isSearching={state.owners.items.length > 0 ? state.owners.isFetching : false}
                            moreSuggestionsAvailable={state.owners.hasMore && !state.owners.isFetching}
                            suggestions={state.owners.items.length > 0 ? state.owners.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                            onGetMoreResults={() => {
                                if (state.owners.items.length > 0 && !state.owners.isFetching) {
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.owners.items.at(state.owners.items.length - 1).internalId);
                                    clintsReq.setNextto(wrapper)
                                    getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                }
                            }}
                            onSuggestionClick={(ev?, item?: any, index?: number) => {
                                var u = state.owners.items.findIndex(e => e.internalId == item.key)
                                if (u >= 0) {
                                    dispatch(setSelectedUserItems([state.owners.items.at(u)]))
                                }

                            }}
                            inputProps={{ placeholder: t("owner"), required: true }}

                            disabled={state.isLoading}
                            selectedItems={state.owners.selected.length > 0 ? state.owners.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username,   imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } as IPersonaProps }) : []}
                            onChange={(e) => {
                                dispatch(setSelectedUserItems([]))
                            }}
                            onEmptyInputFocus={() => {
                                dispatch(resetUsers())
                                clintsReq.setSearch(undefined)
                                clintsReq.setNextto(undefined)
                                dispatch(setIsFilteredOwnersSet(false))
                                getFilterPromise?.abort()
                                getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                return []


                            }}
                            onFilterChanged={onOwnersFilterChanged}
                            endButtons={[
                                {
                                    title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                    onClick: () => {
                                        toggleisTransferOwnershipConfirmationCalloutVisible()
                                        setCurrenctAction(1000)

                                    }
                                }]}
                        />


                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={() => { dispatch(setSelectedUserItems([])); toggleisTransferOwnershipConfirmationCalloutVisible() }}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisTransferOwnershipConfirmationCalloutVisible()
                                    if (state.item && state.owners?.selected?.at(0)?.internalId) {
                                        if (currentAction == 3) {
                                            if (state.item?.isAuthorizationRequired) {
                                                setCurrenctAction(14)
                                                return;
                                            }
                                            let req = new TransferAccountOwnershipRequest();
                                            req.setId(state.item?.internalId)
                                            req.setOwner(state.owners?.selected?.at(0)?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(changeOwnership({ body: req, headers: getHeaders() }))
                                        }
                                    }

                                }} text={t("change")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>

                </Callout>
            ) : null}



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareAccount")} value={`https://${window.location.host}/accounts/${state.item?.internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("closeAccount") : currentAction == 2 ? (state.item?.status == 1) ? t("deactivate") : t("activate") : currentAction == 12 ? (state.item?.isAuthorizationRequired) ? t("authorizationDisable") : t("authorizationEnable") : currentAction == 15 ? t("removeLink") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("closeAccountDesc") : currentAction == 2 ? (state.item?.status == 1) ? t("deactivateFinancialAccountDesc") : t("activateFinancialAccountDesc") : currentAction == 12 ? (state.item?.isAuthorizationRequired) ? t("authorizationDisableDesc") : t("authorizationEnableDesc") : currentAction == 15 ? t("removeLinkDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let req = new CloseAccountRequest();
                                            req.setId(state.item?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(closeAccount({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new UpdateAccountStatusRequest();
                                            req.setId(state.item?.internalId)
                                            req.setStatus(state.item?.status == 1 ? 2 : 1)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateStatus({ body: req, headers: getHeaders() }))


                                        } else if (currentAction == 12) {
                                            setCurrenctAction(13)
                                        } else if (currentAction == 15) {
                                            let req = new RevokeLinkRequest();
                                            req.setId(state.item?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(removeLink({ body: req, headers: getHeaders() }))

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("close") : currentAction == 2 ? (state.item?.status == 1) ? t("deactivate") : t("activate") : currentAction == 12 ? (state.item?.isAuthorizationRequired) ? t("disable") : t("enable") : currentAction == 15 ? t("removeLink") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <Pivot selectedKey={String(selectedKey)} onLinkClick={(i) => {
                        if (i?.props.itemKey == "4")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#signatures")

                        if (i?.props.itemKey == "3")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#linkaccount")

                        if (i?.props.itemKey == "2")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#authorizations")

                        if (i?.props.itemKey == "1")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#statement")

                        if (i?.props.itemKey == "0")
                            window.history.replaceState(null, "", "#info")
                        //navigate("#info")

                        setSelectedKey(i?.props?.itemKey ?? "0")
                    }} >
                        <PivotItem headerText={t("info")} itemKey="0"   >
                            <div ref={ref}>
                                <CommandBar
                                    items={state.item?.isLinkingMetadataSet ? _selectionItemsLinkEdit : _selectionItemsLinkAdd}
                                    farItems={_farOptions}
                                />
                                <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <TextView label={t("id")} value={state.item?.internalId} />


                                        <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                        {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.addedById + "/profile.jpg"}

                                            size={PersonaSize.size40} />

                                            : undefined}

                                    </Stack>
                                    <TextView label={t("accountNumber")} value={state.item?.id} />

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <TextView label={t("accountName")} value={state.item?.name} />

                                        <TextView label={t("accountCode")} value={state.item?.code} />

                                        <TextView label={t("status")} iconName={state.item?.status != undefined ? state.item?.status == AccountStatus.ACTIVE ? "Accept" :
                                            state.item?.status == AccountStatus.INACTIVE ? "Cancel" : state.item?.status == AccountStatus.CLOSED ? "Blocked" : undefined : undefined}
                                            value={state.item?.status != undefined ? state.item?.status == AccountStatus.ACTIVE ? t("active") :
                                                state.item?.status == AccountStatus.INACTIVE ? t("inactive") : state.item?.status == AccountStatus.CLOSED ? t("closed") : "" : ""}
                                        />

                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <TextView label={t("authorization")} iconName={state.item?.isAuthorizationRequired != undefined ? state.item?.isAuthorizationRequired == true ? "LockSolid" :
                                            "Unlock" : "Unlock"}
                                            value={state.item?.isAuthorizationRequired != undefined ? state.item?.isAuthorizationRequired == true ? t("enabled") :
                                                t("disabled") : t("disabled")}
                                        />

                                        <TextView label={t("accountLinking")} iconName={state.item?.isLinkingEnable != undefined ? state.item?.isLinkingEnable == true ? "Link" :
                                            "RemoveLink" : "RemoveLink"}
                                            value={state.item.isLinkingMetadataSet && state.item?.isLinkingEnable ? t("enabled") :
                                                state.item.isLinkingMetadataSet && state.item?.isLinkingEnable == false ? t("disabled") : undefined}
                                        />


                                        <TextView label={t("transferControl")} iconName={"BranchCompare"}
                                            value={state.item?.transferControl == TransferControl.ALL ? t("transferControlAll") :
                                                state.item?.transferControl == TransferControl.NONE ? t("transferControlNone") :
                                                    state.item?.transferControl == TransferControl.ONLY_OWNER_ACCOUNTS ? t("transferControlOwnerOnly") :
                                                        state.item?.transferControl == TransferControl.OWNER_AND_AUTHORIZED_CLIENTS_ACCOUNTS ? t("transferControlOwnerAndAuth") :
                                                            t("transferControlAll")
                                            }
                                        />

                                    </Stack>
                                    {state.balances ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <TextView styles={{ root: { fontWeight: "bold" } }} label={t("freeBalance")} value={state.balances?.freeBalance ? currencyFormatter(state.balances?.freeBalance, { significantDigits: state.balances?.currencyDecimalPlaces ?? 9, symbol: state.balances?.currencySymbol }) : ""} />
                                        <TextView styles={{ root: { fontWeight: "bold" } }} label={t("reservedBalance")} value={state.balances?.reservedBalance ? currencyFormatter(state.balances?.reservedBalance, { significantDigits: state.balances?.currencyDecimalPlaces ?? 9, symbol: state.balances?.currencySymbol }) : ""} />
                                        <TextView styles={{ root: { fontWeight: "bold" } }} label={t("totalBalance")} value={state.balances?.totalBalance ? currencyFormatter(state.balances?.totalBalance, { significantDigits: state.balances?.currencyDecimalPlaces ?? 9, symbol: state.balances?.currencySymbol }) : ""} />

                                    </Stack> :
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            <Spinner size={SpinnerSize.medium} />
                                        </Stack>
                                    }
                                    <LinkView label={t("currency")} value={state.item?.currencyName == "" ? state.item?.currencyId : state.item?.currencyName}
                                        url={"/currencies/" + state.item?.currencyId + ((org == -1) ? "" : "?org=" + org)} />

                                    <LinkView label={t("accountType")} value={state.item?.accountTypeName == "" ? state.item?.accountTypeId : state.item?.accountTypeName}
                                        url={"/account-types/" + state.item?.accountTypeId + ((org == -1) ? "" : "?org=" + org)} />



                                    <ProfileView label={t("owner")} value={state.item?.ownerName == "" ? state.item?.ownerId : state.item?.ownerName}
                                        url={"/customers/users/" + state.item?.ownerId + ((org == -1) ? "" : "?org=" + org)}
                                        text={state.item?.ownerName == "" ? state.item?.ownerId : state.item?.ownerName}
                                        imageUrl={CLIENT_PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.ownerId + "/profile.jpg"}

                                        size={PersonaSize.size40} />
                                    <LinkView label={t("limitation")} value={state.item?.limitationName == "" ? state.item?.limitationId : state.item?.limitationName}
                                        url={"/limitations/" + state.item?.limitationId + ((org == -1) ? "" : "?org=" + org)} />


                                    <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                                    {state.item?.extraFields.map((e: DynamicField, index: number) => {
                                        return <Stack key={e.key}>
                                            <TextView label={e.label ?? ""} value={e?.value} />

                                        </Stack>
                                    })}
                                </Stack>
                            </div>
                        </PivotItem>

                        <PivotItem headerText={t("statement")} itemKey="1"  >
                            <AccountStatementPage account={state.item} />
                        </PivotItem>

                        <PivotItem headerText={t("accountAuthorizations")} itemKey="2"  >
                            <AccountAuthorizationPage account={state.item} />
                        </PivotItem>

                        {state.item?.isAuthorizationRequired == false ?
                            <PivotItem headerText={t("linkAccount")} itemKey="3"  >
                                <LinkAccountPage account={state.item} />
                            </PivotItem>
                            :
                            undefined
                        }
                        {state.item?.isAuthorizationRequired == false ?
                            <PivotItem headerText={t("signatures")} itemKey="4"  >
                                <SignaturesPage account={state.item} />
                            </PivotItem>
                            :
                            undefined
                        }
                    </Pivot>


            }


        </Stack >



    );
}




